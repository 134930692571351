/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.Announcements {
    position: fixed;
    top: navbar-height;
    right: 0;
    z-index: z.announcements;

    .announcement {
        width: 13rem;
        themed background-color bg
        box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.16);
        overflow: hidden;
        .fa-times-circle {
            padding: 0.3rem;
            cursor: pointer;
        }
    }
}

&.zen .Announcements {
    display: none;
}
