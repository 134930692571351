/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.Modal-container {
    position: fixed;
    display: flex;
    //min-height: 100%;
    //height: 100%;
    max-height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: z.modal.container;

    align-items: flex-start;
    align-content: stretch;
    justify-content: center;
}

.Modal-backdrop {
    background-color: rgba(0,0,0,0.2);
    position: fixed;
    z-index: z.modal.backdrop;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}

.Modal {
    @extends .Card;

    themed color fg;
    max-width: 100%;
    max-height: 90vh;
    z-index: z.modal.container;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 0.3rem;

    .header {
        flex: 0;
        padding: 0.5rem;
        padding-top: 1rem;
        padding-left: 2rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid transparent;
        themed border-color shade3;
        min-height: 2rem;
        display: flex;
        align-items: center;
        align-content: center;
        h1, h2, h3, h4, h5 {
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 400;
        }
    }

    .body {
        padding: 0.5rem;
        flex-grow: 1;
        overflow: auto;
        //min-height: 20rem;
    }

    .buttons {
        flex: 0;
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        border-top: 1px solid transparent;
        min-height: 3em;
        themed border-color shade3;
    }
}


/* Safari is stupid and isn't auto-growing the body like every other browser, so
this hack targets safari only */
_::-webkit-:not(:root:root), .Modal .body {
    min-height: 20rem;
}
