/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.EmailBanner-container {
    display: flex;
    justify-content: center;

    .fa-times {
        float: right;
        padding-left: 1rem;
        padding-bottom: 1rem;
        cursor: pointer;
    }
}

.EmailBanner {
    width: 40rem;
    max-width: 100vw;
    text-align: justify;

    .primary {
        float: right;
        margin-left: 1rem;
        margin-top: 1rem;
    }
}
