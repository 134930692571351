/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.ChatDetails {
    box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
    border: 1px solid transparent;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
    themed background-color shade4
    themed border-color shade3
    themed color fg
    max-width: 130px;
    padding: 0.1rem;

    .actions {
        margin-top: 0.1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > button {
            min-width: 98%;
            text-align: left;
            .fa, .ogs-goban {
                padding-right: 0.1rem;
            }
        }
    }
}
