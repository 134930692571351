/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

step-bg=#0068F7
step-fg=#fff

.Steps {
    display: flex;
    align-items: flex-start;
}

.StepContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 3rem;
    min-height: 2rem;

    .title-row {
        display: flex;
        align-items: center;

        .title-left, .title-right {
            flex: 1;
            border-top: 0.2rem solid transparent;
            themed border-color shade3
        }
        .title-text {
            flex-shrink: 0;
            flex-grow: 0;
            font-size: 0.7em;
            padding: 0.3rem;
            border-radius: 1.0rem;
            min-width: 1rem
            min-height: 1rem
            text-align: center;
            border: 2px solid transparent; /* for selected, color overridden down below */
            themed background-color shade3
            user-select: none;

            &.clickable {
                cursor: pointer;
            }
        }
    }

    .step-contents {
        font-size: smaller;
        text-align: center;
    }

}

.CompletedStepContainer {
    .title-row {
        .title-text {
            themed background-color primary
            themed color colored-background-fg
        }
        .title-left, .title-right {
            themed border-color primary
        }
    }
}

.LastCompletedStepContainer {
    .title-row {
        .title-text {
            themed background-color primary
            themed color colored-background-fg
        }
        .title-right {
            themed border-color shade3
        }
    }
}

.SelectedStepContainer {
    .title-row {
        .title-text {
            themed-darken border-color  primary 30%
            themed-lighten background-color primary 30%
        }
    }
}

.StepContainer:first-child {
    .title-row {
        .title-left {
            border-top: none;
        }
    }
}

.StepContainer:last-child {
    .title-row {
        .title-right {
            border-top: none;
        }
    }
}
