.BecomeASiteSupporterModal {
    width: 44rem;
    max-width: 100vw;
    margin-top: 2rem;

    p {
        text-align: justify;
    }

    .header {
        padding-left: 1rem;
    }
    .body {
    padding: 1rem;
    }

    b {
        themed-important color fg-increased-contrast
    }
}
