/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.LeaderBoard {
    .row {
        margin: 2em;
    }
    h3,h4,h5 {
        white-space: nowrap;
    }
    h2 {
        > span {
            color: silver;
            margin: 0 0.5rem;

            &:hover {
                color: grey;
            }
        }
    }
    .points {
        font-size: 0.8em;
        font-family: fixed;
        //color: mix(@text-color, #888, 50%);
        color: #888;
    }

    .entry {
        overflow: hidden;
        white-space: nowrap;
    }


}
