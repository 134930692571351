/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.LanguagePicker {
    themed color text-color
    padding-right: 1.0rem;
    i {
        margin-right: 0.3rem;
    }
}

.LanguagePickerModal {
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 30rem;

    .body {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    .footer {
        text-align: center;
        padding-bottom: 1rem !important;
    }

    .language-option {
        display: inline-block;
        flex-grow: 1;
        flex-shrink: 1;
        width: 32%;
        max-width: 32%;
        border: 1px solid transparent;
        padding-left: 10%;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        box-sizing: border-box;
        border-radius: 0.2rem;
        themed color text-color
    }
    .selected {
        themed border-color primary
    }

    .auto {
        themed border-color success
        themed color shade2
    }



    // here
}
