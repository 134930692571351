/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.Group {
    .banner {
        text-align: center;
        img {
            max-width: 100vw;
            max-height: 20vh;
        }

        .fa-picture-o {
            font-size: 15vh;
            themed color shade3
        }
        .fa-picture-o:hover {
            themed color shade2
        }
    }
    .admins {
        margin-bottom: 1rem;

        >b, >span {
            margin-right:1rem;
        }
    }

    .fa-pencil, .fa-save {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
    }

    .Dropzone {
        cursor: pointer;
        border: 2px dashed #888;
    }
    .Dropzone-128 {
        height: 128px;
        width: 128px;
    }

    .Player {
        margin-right:0.5em;
    }

    textarea {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .pad {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .new-news {
        margin: 0.5rem;
        input {
            width: 100%;
        }
    }

    .news th.none {
        display: none;
    }

    .Card {
        overflow-x: auto;
    }

    button {
        margin: 0 0.5rem 1rem 0;
    }

    .EmbeddedChat {
        flex-shrink: 0;
        flex-grow: 1;
        min-width: 15rem;
        flex-basis: 15rem;
        overflow: hidden;
        .Chat {
        }
    }
}
