/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

#docs-other-go-resources {
    div.multi-columns{
        text-align: left;
    }

    dl {
        display: inline-block;
        margin: 2em;
        width: 100%;
        vertical-align: top;
    }

    dt {
        font-size: 1.3em;
        margin-bottom: 0.5rem;
    }
    dd {
        margin-inline-start: 1rem;
    }

    em {
        display: block;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    h1 {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    ol {
        padding: 1em;
        margin-bottom: 2em;
    }

    pre {
        font-size: 0.8em;
    }
}

#docs-other-go-resources {
    div.multi-columns{
        column-count: 4;
        column-gap: 20px;
    }
}
@media only screen and (max-width: 1023px) {
    #docs-other-go-resources {
        div.multi-columns{
            column-count: 3;
            column-gap: 20px;
        }
    }
}
@media only screen and (max-width: 800px) {
    #docs-other-go-resources {
        div.multi-columns{
            column-count: 2;
            column-gap: 20px;
        }
    }
}
@media only screen and (max-width: 600px) {
    #docs-other-go-resources {
        div.multi-columns{
            column-count: 1;
            column-gap: 20px;
        }
    }
}
