/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.AnnouncementCenter {

    text-align: center;

    .announcements {
        display: table;
        text-align: left;
        margin-top: 3rem;
    }
    .announcement {
        display: table-row;
    }
    .cell {
        display: table-cell;
        padding-right: 0.5rem;
    }

    dl {
        margin: auto;
        display: inline-block;
        width: 40rem;
        max-width: 100vw;
    }

    input {
        width: 30rem;
        max-width: 90vw;
    }

    .announcement-history {
        text-align: left;
        td {
            min-width: 7rem;
            padding-right: 1.0rem;
        }
    }
}
