/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


#LearningHub-container {
    display: flex;
    justify-content: center;
}

#LearningHub {
    width: 100%;
    max-width: 80rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;

    .fa-star {
        color: #FFA100;
        text-shadow: 0 0 2px #FFF300;
    }
    .MiniGoban {
        height: 64px;
        width: 64px;
        margin: 0.5rem;

    }
}

#LearningHub-Index {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .learning-hub-active {
        background-color: #3893E8;
        color: #fff;
    }


    #LearningHub-list {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 4rem;


        .section {
            margin-top: 3rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            > h2 {
                flex: 1;
                text-align: right;
                width: 30%;
                min-width: 15rem;
                letter-spacing: 8px;
                text-transform: uppercase;
                themed(color, shade2);
                padding-right: 3rem;
            }

            > .contents  {
                flex: 1;
                min-width: 24rem;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .under-construction {
                background-image: repeating-linear-gradient( -45deg, darken(#FFCC00, 30%), darken(#FFCC00, 10%) 10px, #000 10px, #000 20px);
            }

            .Card {
                display: flex;
                align-items: center;
                text-decoration: none;
                width: 23rem;
                height: 5rem;
                color: #f9f9f9;
                border-radius: .3rem;
                text-align: left;

                i.fa-thumbs-up , i.fa-desktop , i.fa-puzzle-piece , i.ogs-goban, img {
                    width: 4rem;
                    height: 4rem;
                    margin: 1.0rem
                    margin-left: 0.5rem
                }

                i.fa-thumbs-up {
                    font-size: 4rem;
                    margin-left: 0.7rem;
                    margin-right: 0.8rem;
                }
                i.fa-desktop {
                    margin-top: 1.2rem;
                    margin-bottom: 0.8rem;
                    font-size: 4rem;
                }
                i.fa-puzzle-piece {
                    margin-top: 1.2rem;
                    margin-bottom: 0.8rem;
                    margin-left: 1.0rem;
                    margin-right: 0.5rem;
                    font-size: 4rem;
                }
                i.ogs-goban {
                    font-size: 4rem;
                    margin-left: 0.7rem;
                    margin-right: 0.8rem;
                }

                h1, h2, h3 {
                    margin: 0;
                }

                cursor: pointer;
            }
        }
    }

}


.LearningHub-section-nav {
    display: flex;
    flex-direction: column;
    width: 17rem;
    margin: 2rem;
    margin-top: 5rem;

    > a { /* main menu */
        font-size: font-size-big;
        themed(color, shade0);
    }

    .progress-text {
        float: right;
    }

    h2 {
        margin: 0;
        padding: 0.3rem;
        padding-left: 0;
        text-wrap: nowrap;
        themed(color, shade2);
        &:hover {
            themed(color, shade0);
        }

        border-bottom: 1px solid #000;
        themed(border-color, shade3);
    }

    .section {
        display: flex;
        flex-direction: column;
        a {
            padding: 0.5rem;
            themed(color, shade1);
            text-decoration: none;
            &:hover {
                themed(color, shade0);
            }

            &.active {
                themed(background-color, primary);
                themed(color, colored-background-fg);
            }
        }

    }

    .reset-progress {
        font-size: font-size-small;
        themed(color, shade2);
        cursor: pointer;
        padding: 0.5rem;

        &:hover {
            themed(color, shade0);
        }
    }
}


.LearningPage {
    flex: 1;
    padding-top: 4rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;
    align-items: center;

}




.LearningPage-pages {
    margin-left: 1rem;
    min-height: 18rem;
    width: 15rem;
    border: 1px solid #0f0;
    themed(border-color, shade4);

    display: flex;
    flex-direction: column;

    .header {
        text-align: center;
        themed-darken(background-color, primary, 00%);
        themed(color, colored-background-fg);
    }

    .text {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: stretch;
        align-content: center;
        justify-content: center;
        text-align: center;
        > div {
            flex: 1;
            padding: 1rem;
        }

        .failed {
            themed-darken(background-color, reject, 30%);
            themed(color, colored-background-fg);

            .reject {
                animation: pulsate-reject  2.0s infinite ease-in-out;
            }
        }

        .complete {
            themed(background-color, success);
            themed(color, colored-background-fg);
        }

    }

    .pages {
        display: flex;
        flex-direction: row;

        .page {
            flex: 1;
            text-align: center;
            padding: 0.3rem;
            border-left: 1px solid #0f0;
            border-top: 1px solid #0f0;
            themed(border-color, shade4);
            themed(color, shade1);
            cursor: pointer;

            &.active {
                themed(background-color, primary);
                themed(color, colored-background-fg);
                &:hover {
                    themed-lighten(background-color, primary, 20%);
                    themed(color, colored-background-fg);
                }
            }

            &:first-child {
                border-left: 0;
            }

            &:hover {
                text-decoration: none;
                themed(color, shade0);
                themed(background-color, shade4);
            }
        }

    }
}


@media (max-width: 40rem) {

    .LearningPage {
        padding-top: 0rem;
    }

    .LearningPage-pages {
        width: 95%;
    }
    .LearningHub-section-nav {
        width: 95%;
    }
}
