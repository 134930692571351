/*
 * Copyright (C) 2012-2017  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.Joseki {
    top: below-navbar;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
    @media screen and (min-width: 801px) {
        flex-direction: row;
        position: absolute;
    }

    align-items: stretch;

    .left-col {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 45%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;

        .goban-container {
            flex-grow: 1;
            //flex-shrink: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .Goban {
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        @media screen and (min-width: 801px) {
            padding-left: 1 em;
        }
        padding-top: 1em;

        @media screen and (max-width: 801px) {
            height: 100vw;
            max-height: 100vw;  // firefox needs this
            flex-basis: 99%;
        }
    }

    .admin-mode {
        flex-basis: 15%;
    }

    .right-col {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 55%;
        overflow-x: hidden;
        min-height: auto;
        flex-direction: column;
        padding-left: 0.5rem;
        min-width: 340px;

        padding-right: 0;
        display: flex;

        @media screen and (min-width: 1161px) {
            height: 100%;
            max-height: 100%;
        }

        @media screen and (max-width: 1160px) {
            overflow-y: visible;
        }

        .top-bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            @media screen and (max-width: 800px) {
                flex-direction: column;
            }
            @media screen and (min-width: 800px) {
                flex-direction: row;
            }

            .throbber-spacer {
                min-height: 38px;
                min-width: 32px;
            }

            .top-right {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                alighn-items: center;

                a {
                    margin: 0.2rem;
                }
            }

            .top-bar-other {
                display: flex;
                flex-direction: row;
            }

            .joseki-help {
                .fa {
                    themed color shade1;
                    padding: 0.3rem;
                }
            }

            .move-controls {
                display: flex;
                align-items: center;

                i {
                    cursor: pointer;
                    margin-right: 1em;
                }

                .pass-button {
                    cursor: pointer;
                    border: #bbb;
                    border-style: solid;
                    border-width: 1px;
                    padding: 0px 5px 0 5px;
                    border-radius: 4px;
                    margin-right: 1rem;
                    background: inherit;
                    box-shadow: none;
                    line-height: unset;
                    height: fit-content;
                }

                // get rid of ugly selected-border on button
                // not accessibility friendly, but OGS isn't anyhow
                .pass-button:focus {
                    outline: 0;
                }

                .pass-button:hover {
                    themed background-color shade5
                }

                // can't theme box-shadow, so just using "light" version, and chose
                // a colour for that that looks OK in both themes
                .ideal {
                    border-color light.joseki-ideal;
                    border-width: 2px;
                    box-shadow: 0 0 0.8rem light.joseki-ideal;
                }

                .good {
                    border-color light.joseki-good;
                    border-width: 2px;
                    box-shadow: 0 0 0.8rem light.joseki-good;
                }

                .trick {
                    border-color light.joseki-trick;
                    border-width: 2px;
                    box-shadow: 0 0 0.8rem light.joseki-trick;
                }

                .question {
                    border-color light.joseki-question;
                    border-width: 2px;
                    box-shadow: 0 0 0.8rem light.joseki-question;
                }

                .mistake {
                    border-color light.joseki-mistake;
                    border-width: 2px;
                    box-shadow: 0 0 0.8rem light.joseki-mistake;
                }
            }


            .move-controls.highlight {
                .fa {
                    color: gold;
                }
            }

            .mode-control {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                margin-right: 0.5rem; // space for the help icon in the top right

                .btn {
                    min-width: 5em;
                }

               .selected {
                    themed background-color success
                    themed-darken border-color success border-darken-amount
                }
            }
        }

        .play-columns {
            flex-grow: 1;

            flex-basis:auto;
            display: flex;

            .fa-filter {
                align-self: flex-end;
                margin-right: 0.5em;
                padding: 3px;
            }
        }

        .play-dashboard {
            flex-grow: 1;

            flex-basis:50%;
            display: flex;
            flex-direction: column;
            padding-top: 3rem;
            padding-bottom: 1rem;

            .fa {
                margin-right: 3px;
                min-width: 1.25rem;
            }

            .fa-check, .fa-star {
                color: gold;
            }

            .fa-times {
                color: red
            }
        }

        .edit-container {
            flex-grow: 1;
            flex-shrink:0;
            flex-basis:auto;
            display: flex;
            flex-direction: column;
        }

        .position-child-count {
            margin-bottom: 5px;
            font-style: italic;
            padding-right: 0.2rem;
        }

        .explore-pane {
            @media screen and (min-width: 1161px) {
                flex-direction: row;
                min-height: 20rem;  // prevent this from being shrunk too small when continuations pane grows big
            }

            @media screen and (max-width: 1160px) {
                flex-direction: column;
                overflow-y: visible;
                justify-content: space-between;
                min-height: fit-content;
            }

            flex-grow: 1;
            flex-basis: auto;
            display: flex;
            margin-left: 0.5rem;
            margin-bottom: 0.2rem;

            overflow-y: hidden;
        }

        .description-column {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-basis: 50%;

            .position-description {
                flex-basis: 80%;
            }
        }

        .position-tag {
            margin-bottom: 0.2rem;
        }

        .position-tag span {
            margin: auto;
            padding-left: 3px;
            padding-right: 3px;
            border-radius: 3px;

            themed background-color shade4;
        }

        .position-joseki-source {
            padding-bottom: 1.5em;
            span {
                padding-right: 0.5em;
            }
        }

        .extra-info-open {
            themed background-color shade5;
            border-radius: 5px;
        }

        .extra-info-column {
            margin-top: 3em;
            margin-bottom: 1em;
            margin-left: 1em;

            @media screen and (max-width: 1160px) {
                margin-right 1em;
                min-height: 17rem;
            }

            flex-grow: 1;
            flex-basis: 50%;  // 50-50 left/right columns

            display: flex;
            flex-direction: column;

            overflow-y: hidden;  // needed to contain the audit-container to the right size.

            .extra-info-selector {
                margin-bottom: 0.5rem;
                text-align: center;
            }

            .audit-container {
                flex-grow: 1;
                overflow-y: scroll;
                // a hack to make it fit along with the extra-info-selector into extra-info pane
                // I wish I knew why the overflow-y of extra-info-column doesn't contain this div, instead this div grows unlimited
                // without this statement.
                max-height: 90%;
            }

            .extra-info-header {
                display: flex;
                padding-left: 3px;
                flex-direction: row;
                justify-content: space-between;
                padding-right: 0.5em;
                align-items: center;
                themed background-color shade4;
            }

            .discussion-container {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: space-between;
                overflow-y: hidden;

                .discussion-lines {
                    overflow-y: scroll;

                    .comment-text {
                        padding-left: 0.5em;
                        overflow-wrap: break-word;
                        p {  // This p is generated by the <Markdown>
                            margin: 0 0 0.3rem 0;
                        }
                    }
                }

                .comment-input {
                    resize: vertical;
                    margin-bottom: 3px;
                    overflow-y: hidden;
                    padding: 3px;
                    border-radius: 3px;
                    min-height: 1.5rem;
                    themed border-color shade3;
                }
            }

            .comment-header, .audit-header {
                display: flex;
                padding-left: 3px;
                flex-direction: row;
                justify-content: space-between;
            }

            .comment-date, .audit-date {
                font-size: smaller;
                padding-right: 0.5em;
            }

            .extra-info-header .fa {
                margin-bottom: 0;
            }

            .fa-filter {
                padding-left: 0.5em;
                color: grey;
            }

            .filter-active {
                color: #0062e9;
                border-radius: 4px;
            }

            .play-results-container {
                padding-left: 1rem;
                h4 {
                    margin-bottom: 0.3rem;
                }
                div {
                    margin-left: 0.5rem;
                }
            }
        }

        .hide {
            visibility: hidden;
        }

        .move-attributes {
            display: grid;
            grid-template-colums: 1fr 1fr;
            grid-template-rows: auto;
            grid-row-gap: 1em;

            margin-top: 1em
            margin-bottom: 1em;
            padding-right: 1em;

            .move-type-selection {
                span {
                    margin-right: 0.5em;
                }
                button {
                    margin-left 1em;
                }
            }
            .variation-order-select {
                grid-column: 2 / 2;
                justify-self: end;

                span {
                    margin-right: 0.5em;
                }
            }

            .joseki-source-edit {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .joseki-source-edit-controls {
                    display: flex;
                    align-items: center;

                    .fa {
                        padding-left: 0.5rem;
                    }
                }
            }

            .joseki-source-edit, .tag-edit {
                @media screen and (max-width: 1160px) {
                    grid-column: span 2;
                }
            }
        }

        .description-edit {
            flex-grow: 2;
            display: flex;
            flex-direction: column;
            margin-right: 0.5rem;

            .edit-label {
                font-style: italic;
                font-size: smaller;
            }

            textarea {
                resize: vertical;
            }

            .description-preview {
                padding-left: 0.5rem;
            }

            .position-edit-button {
                width: 100%
                display: flex;
                justify-content: flex-end;
            }
        }

        .position-details {

            @media screen and (min-width: 1160px) {
                flex-direction: row;
            }

            @media screen and (max-width: 1160px) {
                flex-direction: column;
            }

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            padding-top: 0.3rem;
        }

        .status-info {
            @media screen and (min-width: 1160px) {
                flex-basis: 50%;     // share the space with continuations pane
                overflow-x: hidden;  // Make sure long "Moves Made" doesn't grow this box
                min-height: 12rem;   // Match continuations pane
            }

            display: flex;
            flex-direction: column;

            .hide {
                display: none;
            }

            .moves-made {
                display: flex;
                justify-content: flex-start;
                padding-right: 1em;

                .moves-made-string {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    direction: rtl;  // ellipsis on left
                }
            }
        }

        .continuations-pane {
            flex-basis: 50%;  // 50/50 share the space with status info

            @media screen and (max-width: 1160px) {
                align-items: flex-start;
                padding-top: 1em;
            }

            display: flex;
            flex-direction: column;
            align-items: flex-end;

            min-height: 12rem;

            margin-bottom: 0.3rem;

            .position-child-count  {
                background: inherit;
                border: none;
                box-shadow: none;

                .fa {
                    padding-left: 0.3rem;
                    padding-right: 0.3rem;
                }
            }

            .child-count-details-pane {
                min-width: 18rem;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            .details-pane-open {
                align-items: flex-start;
                themed background-color shade5;
            }

            .count-details {
                padding-left: 0.2rem;
                padding-right: 0.5rem;
            }

            .variation-count-item span {
                margin-right: 0.5rem;
            }

        }
    }
}
