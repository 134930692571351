
.ActiveAnnouncements {
    //text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 60rem;
    margin: auto;
    margin-top: 1.0rem;
    text-align: center;

    div {
        text-align: left;
        display: inline-block;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
}
