/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

#Register {
    display: flex;
    flex-direction: column;
    //justify-content: space-around;
    justify-content: space-around;
    align-items: center;
    //min-height: 100vh;

    .Card {
        padding: 1rem;
        width: 15rem;
        max-width: 100%;

        h2 {
            margin-top: 0;
        }

        input {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    .LineText {
        themed color shade1
        margin-bottom: 1rem;
    }

    .social-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;

        a.md-icon {
          display: flex;
          align-items: center;
          padding: 0.5rem;
          background-color: #ffffff;
          width: 100%;
          height: 2.4rem;
          cursor: pointer;
          &+ a.md-icon {
              margin-top: .5rem;
          }

          img {
              width: 1.4rem;
              height: 1.4rem;
              margin-right: .5rem;
          }

        }

      }

    .error-message {
        margin-top: -0.5rem;
        margin-bottom: 1.0rem;
        padding: 0.5rem;
    }
}
