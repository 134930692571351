/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.AIReview {
    .reviewing, .pending {
        text-align: center;
        width: 100%;

        .fa-desktop {
            margin-right: 0.5rem;
            themed color info
        }
    }

    .key-moves {
        text-align: center;
        font-size: font-size-mid;
        margin: 0.5rem;
        user-select: none;

        .btn {
            float: right;
        }
    }
    .full-ai-review {
        margin-left: 1rem;
    }
    .key-move {
        margin-left: 0.5rem;
    }

    .increased-win-rate, .decreased-win-rate {
        margin-left: 0.1rem;
        display: inline-block;
        width: 2rem;
        text-align: right;
    }
    .increased-win-rate {
        color: green;
    }
    .decreased-win-rate {
        color: red;
    }
    .ai-review-option-container {
        display: flex;
        //width: calc(100% - 3rem);
        //width: 100%;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        //padding-left: 0.5rem;

        .ai-review-strength-icon {
            flex: 0;
            width: 1.1rem;
            flex-basis: 1.1rem;
            margin-left: 0.2rem;
            margin-right: 0.5rem;
        }

        .ai-review-information {
            flex: 1;
        }

        .date {
            font-style: italic;
        }
    }
    .ai-review-win-rate-container {
        display: inline-flex;
        width: calc(100% - 3rem);
        padding-left: 0.5rem;
    }
    .prediction {
        display: flex;
    }
    .prediction, .ai-review-win-rate-container {
        text-align: center;

        align-items: center;
        .ai-review-list-container {
            flex-grow: 0;
            flex-shrink: 0;
            position: relative;

        }
        .progress {
            display: inline-flex;
            flex-grow: 1;
            flex-shrink: 1;
            overflow: hidden;
        }
        .ai-review-more-info-container {
            flex-grow: 0;
            flex-shrink: 0;
            position: relative;
            margin-right: 0.2rem;

        }

        .ai-review-network-size-container {
            flex-grow: 0;
            flex-shrink: 0;
            font-size: font-size-smaller;
            padding-right: 0.2rem;
            padding-left: 0.2rem;
            padding-top: 0.2rem;
        }


    }
    .next-move-delta-container {
        text-align: center;
    }
    .next-move-coordinates {
        //border: 1px solid #000;
        border: 1px solid #000;
        &.positive {
            border-color: #00bd0c;
        }
        &.negative {
            border-color: #ff7697;
        }
        &.neutral {
            border-color: #bda100;
        }

        background-color: #00b0dd;
        border-radius: 0.3rem;
        margin: 0.5rem;
        color: #000;

        display: inline-block;
        width: 4rem;
        text-align: center;
        //padding-right: 0.5rem;
    }
    .next-move-delta {
        display: inline-block;
        width: 5rem;
        text-align: left;

        &.negative {
            //themed color reject
        }
        &.positive {
            //themed color success
        }
    }

    .ai-review-new-review {
        display: flex;
        justify-content: space-around;
        cursor: default;

        button {
            display: inline-flex;
            align-items: center;
            i { 
                margin-right: 0.5rem;
            }
        }
    }

    .worst-move-list-container {
        user-select: none;
        display: inline-flex;
        width: 100%;

        .move-list {
            flex: 1;
        }

        .move {
            margin-left: 0.2rem;
            margin-right: 0.2rem;
            border-radius: 0.2rem;
            border: 1px solid #888;
            width: 2.5rem;
            display: inline-block;
            text-align: center;
            cursor: pointer;
        }

        .filter {
            flex: 0;
        }
    }
}

.ai-review-list, .ai-review-more-info {
    padding: 0.5rem;
    overflow-y: auto;

    box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
    border: 1px solid transparent;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16), 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    themed background-color shade4
    themed border-color shade3
    themed color fg

    z-index: z.popover;
    themed background-color shade5

    .ai-review-item {
        &.selected, &:hover {
            cursor: pointer;
            themed background-color shade3
        }
    }

    th {
        text-align: right;
        padding-right: 0.5rem;
    }
}


.ai-review-fast,
.ai-review-strength-0,
.ai-review-strength-1,
.ai-review-strength-2,
.ai-review-strength-3,
{
    display: inline-flex;
    width: 1.1rem;
    height: 1rem;
    margin-right: 0.3rem;
    margin-left: 0.2rem;
    border: 2px solid #000;
    border-radius: 50%;

    font-family: "Times New Roman", Times, serif;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
}

.ai-review-strength-0,
.ai-review-strength-1,
.ai-review-strength-2,
.ai-review-strength-3 {
    themed color supporter;
    themed border-color supporter;
}
.ai-review-fast { 
    color: #888; 
    border-color: #888;
}
.ai-review-list-container {
    border: 1px solid red;
}
