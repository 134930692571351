/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

#TournamentRecord {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    width: 100vw;
    max-width: 60rem;
    margin: auto;

    .name-editor, h1.name {
        font-size: 2rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .center {
        align-items: center;
    }

    .space-between {
        width: 100%;
        justify-content: space-between;

        textarea {
            flex-grow: 1;
            margin-right: 1rem;
        }
    }

    .round-line {
        input, h3 {
            margin-right: 1rem;
        }
    }

    .hr {
        height: 1rem;
        border-bottom: 1px solid #888;
        themed border-color shade3
        margin-bottom: 1rem;
    }

    h1, h3 {
        margin: 0;
        padding: 0;
        padding-right: 1rem;
    }

    .round-entries-container {
        max-width: 100vw;
        overflow-x: auto;
    }

    .round-entries {
        width: 100%;

        .round-entry {
            .sgf {
                display: inline-block;
                min-width: 3rem;
                white-space: nowrap;
                overflow: hidden;

                &.with-comments {
                    min-width: 10rem;
                }
            }

            .name {
                display: inline-block;
                min-width: 15rem;
                max-width: 50vw;
                overflow: hidden;

            }

            .Player {
                display: inline-block;
                min-width: 8rem;
                max-width: 15vw
                overflow: hidden;
            }
        }
    }


    .round {
        margin-top: 2rem;

        .round-name {
            margin-top: 1rem;
        }

    }

    .add-player {
        margin: 1rem;

        .new-player-name {

        }

        select {
            border: none;
        }

    }

    .fa-trash {
        cursor: pointer;
        themed color shade2
        &:hover {
            themed color shade0
        }
    }
}
