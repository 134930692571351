/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.Play {
    // here
    max-width: 1110px;

    .seek-graph-container {
        width: 100%;
        height: 22em;
        max-width: 100%;
        max-height: 100%;
    }

    .Card {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        box-sizing: border-box;
    }

    .automatch-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .automatch-header {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 2.5rem;
        font-size: 1.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-group {
            display: flex;
            flex-wrap: nowrap;
        }
    }

    .automatch-row-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .automatch-row {
        display: flex;
        justify-content: space-around;
        align-content: stretch
        width: 100%;
        flex-wrap: wrap;

        button {
            font-size: 1.1rem;
            font-weight: bold;
            flex-grow: 1;
            flex-basis: 13rem;
            max-width: 13rem;
            height: 4rem;
            margin: 0.5rem;
            white-space: nowrap;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;

            .play-button-text-root {
                position: relative;
                height: 1.5rem;


                .time-per-move {
                    position: absolute;
                    bottom: -1.2rem;
                    left: 0;
                    right: 0;
                    text-align: center;
                }
            }

            .fa, .ogs-turtle {
                margin: 0.2rem;
            }

            .ogs-turtle {
                position: relative;
                width: 1.0rem;
            }
            .ogs-turtle::before {
                position: absolute;
                top: -0.5rem;
                left: 0;
            }

            .time-per-move {
                font-size: font-size-smaller;
            }
        }
    }

    .automatch-settings {
        font-size: 1rem;
        flex: 0;
        text-align: left;
        white-space: nowrap;

        .automatch-settings-link {
            cursor: pointer;
            user-select: none;
        }

        i {
            padding-right: 0.25rem;
        }
    }

    .automatch-settings-corr {
        flex: 0;
        text-align: justify;
        font-size: 1rem;
        padding: .5rem;
        padding-left: 3rem;
    }

    .custom-game-row {
        display: flex;
        justify-content: space-around;
        align-content: stretch
        width: 100%;

        button {
            font-size: 1.1rem;
            font-weight: bold;
            flex-grow: 1;
            width: 100%;
            max-width: 13rem;
            margin: 0.5rem;
            height: 4rem;

            .fa {
                margin: 0.2rem;
            }
        }
    }

    .custom-game-header {
        display: flex;
        justify-content: space-around;
        align-content: stretch
        width: 100%;
        justify-content: space-between;
        align-items: left;
        font-size: 1.3rem;
        margin-top: 1rem;
    }

    .showall-selector {
        width: 100%;
        text-align: left;
        font-size: smaller;
        padding-top: 1rem;
    }
}


#challenge-list-container {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
    max-width: 100vw;
    overflow-y: auto;
}

#challenge-list-inner-container {
    width: 100%;
    display: inline-block;
}

#challenge-list {
    display: table;
    width: 100%;
    .ineligible {
        themed color shade3
        font-size: 0.9em;
        vertical-align: middle;
        cursor: help;
    }

    // Flex to escape from the table
    .custom-games-list-header-row {
        display: flex;
        font-weight: bold;
        white-space: nowrap;
        padding-bottom: 0.5 rem;
    }

    .challenge-row {
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        display: table-row;
        font-size: 0.85em;
        white-space: nowrap;

        .head {
            padding-left: 0.5em;
            display: table-cell;
            text-align: center;
            font-weight: bold;
            font-size: 0.8em;
        }
        .head.time-control-header {
            padding-left: 4em;
            text-align: left;
        }

        .cell {
            padding-left: 0.5em;
            display: table-cell;
            text-align: left;
            position: relative;
        }

        .cheat-alert {
            color: chocolate;
            cursor: help;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-left: .5em;
        }

        .cell .cheat-alert-tooltiptext {
            visibility: hidden;
            background-color: chocolate;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px;

            /* Position the tooltip */
            position: absolute;
            z-index: 1;
            bottom: 1em;
            left: 75%;

            width: 10em;
            white-space: normal;
        }

        .cell:hover .cheat-alert-tooltiptext {
            visibility: visible;
        }

        .break {
            border-top: 1px solid #888;
            border-bottom: 1px solid #888;
        }

        .bold {
            font-weight: bold;
            font-size: 1.1em;
        }


        // automatch condition stylings
        .no-preference {
            themed color shade2;
        }
        .required {
            font-weight: bold;
        }
        .preferred {

        }
    }


    .automatch-challenge-row {
        .cell {
            text-align: center;
        }
    }
}
