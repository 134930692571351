/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/* This dock-inline-height needs to match the 'squashed' threshold in Game.tsx:goban_view_squashed() */
dock-inline-height=500px

.Dock {
    user-select: none;
    position: fixed;
    top: navbar-height + 0.2rem;
    right: dock-collapsed-width - dock-width;
    width: dock-width;
    themed background-color bg
    border-radius: 0.5rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: z.dock;

    // note that transition speed is controlled dynamically by javascript based on preferences
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.26);

    &:hover {
        right: 0;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.26);

        // note that transition speed is controlled dynamically by javascript based on preferences
    }

    div.line-item {
        padding-left: 0.5rem;
        display: block;
        cursor: pointer;
        text-align: left;
    }
    a {
        padding-left: 0.5rem;
        display: block;
        cursor: pointer;
        text-align: left;
        display: flex;
        align-items: center;
        themed color text-color;
    }
    a:hover {
        text-decoration: none;
    }
    i.fa, i.ogs-coordinates, i.ogs-zen-mode, i.ogs-goban {
        font-size: 1.3rem;
        width: 1.3rem;
        margin-right: 0.7rem;
        text-align: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-decoration: none;
    }

    ul {
        list-style:none;
        padding-left:0;
    }
    li {
        list-style-type: none;
    }

    .greyed {
        themed color shade3;
        padding-left: 0.5rem;
    }
}

.Dock.inline {
    position: relative;
    right: 0;
    width: 100%;
    display: flex;
    box-shadow: none;
    themed background-color clear

    flex-wrap: wrap;

    > a {
        font-size: 0.7rem;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 30%;
        text-align: left;
        display: flex;
        min-width: 7rem;
        align-items: center;
        i {
            font-size: 1.0rem;
        }
    }

    > hr {
        display: none;
        flex-grow: 0;
        flex-shrink: 1;
        height: 0.5rem;
        max-height: 0.5rem;
        min-width: 99%;
    }

    div.line-item {
        display: inline-block;
        padding-right: 1rem;
    }
    &:hover {
        box-shadow: none;
    }
}

@media (max-height: dock-inline-height) {
    .Dock {
        position: relative;
        right: 0;
        width: 100%;
        display: flex;
        box-shadow: none;
        themed background-color clear

        flex-wrap: wrap;

        > a {
            font-size: 0.7rem;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 30%;
            text-align: left;
            display: flex;
            min-width: 7rem;
            align-items: center;
            i {
                font-size: 1.0rem;
            }
        }

        > hr {
            display: none;
            flex-grow: 0;
            flex-shrink: 1;
            height: 0.5rem;
            max-height: 0.5rem;
            min-width: 99%;
        }

        div.line-item {
            display: inline-block;
            padding-right: 1rem;
        }
        &:hover {
            box-shadow: none;
        }
    }
}




