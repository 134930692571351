/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.NewGameModal {
    width: 44rem;
    max-width: 100vw;
    margin-top: 2rem;

    .body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: 1rem;

        .board-size-selection {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
            button {
                width: 5rem;
            }
        }

        .new-game-button {
            display: inline-block;
            width: 13rem;
            height: 8rem;
            themed background-color shade5
            margin: 0.5rem;
            border-radius: 1rem;
            text-align: center;
            cursor: pointer;
            border: 1px solid transparent;

            h2 {
                font-size: font-size-big;
                margin: 0;
                padding: 0;
            }

            .fa, .ogs-turtle {
                font-size: 2rem;
                margin: 0.5rem;
            }

            &.disabled {
                cursor: not-allowed;
                themed background-color shade3
            }
        }

        .new-game-button:hover {
            themed background-color shade4
            themed border-color shade3

            &.disabled {
                themed background-color shade3
            }
        }
    }

    .buttons {
        justify-content: center;
    }
}
