
.SiteSupporterText {
    .table-container {
        text-align: center;

        table {
            margin: auto;
            th {
                max-width: 7rem;
            }
        }
    }

    .fineprint {
        margin-top: 3rem;
        font-size: font-size-small;
    }
}
