/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.ProfileCard {
    display: flex;
    min-height: 80px;
    align-items: bottom;

    .view-and-edit-link {
        text-align: right;
    }
    .profile-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        justify-content: space-between;
    }
    .PlayerIcon{
        width: 80px;
        height: 80px;
    }
}
