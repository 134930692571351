/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.ACLModal.Modal {
    width: 35rem;
    max-width: 100vw;
    min-height: 25rem;

    .fa-remove {
        margin-right: 0.5rem;

        &:hover {
            text-decoration: none;
        }
    }


    .acl-entries {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1rem;

    }

    .acl-entry {
        width: 32%;
    }

    .grant {
        display: flex;
        flex-justify: spacing-between;
        margin: 1rem;
        .PlayerAutocomplete, .GroupAutocomplete {
            margin-right: 1rem;
        }

        button {
            flex: 0;
            flex-basis: 8rem;
        }

        div {
            white-space: nowrap;
        }
    }
}
