/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.Card {
    margin: 0.5rem;
    padding: 0.5rem;
    display: block;

    border: 1px solid transparent;
    themed background-color card-background-color
    themed border-color shade3
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16), 0px 2px 5px 0px rgba(0, 0, 0, 0.15);

    &.next {
        background-color: #3EA2FF !important;
        &:hover {
            background-color: #3893e8 !important;
            transition: background-color 0.3s ease;
        }
    }

    &.todo {
        background-color: #F29E47 !important;
        &:hover {
            background-color: #f57c00 !important;
            transition: background-color 0.3s ease;
        }
    }

    &.done {
        background-color: #56C75B !important;
        &:hover {
            background-color: #4CAF50 !important;
            transition: background-color 0.3s ease;
        }

    }
}




.fab {
    border-radius: 50%;
    color: #fff;
    text-align: center;
    position: relative;
    display: inline-block;
    user-select: none;
    box-sizing: border-box;

    width: 40px;
    height: 40px;
    padding: 8px;
    font-size: 16px;
    margin: 5px;
    cursor: pointer;

    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16),  0px 2px 5px 0px rgba(0, 0, 0, 0.26);

    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.16), 0px 4px 7px 0px rgba(0, 0, 0, 0.26);
    }

    svg {
        themed fill colored-background-fg
    }

    &.large {
        width: 56px;
        height: 56px;
        padding: 16px;
        font-size: 16px;
    }
}
