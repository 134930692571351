/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


supporter-page-width=60rem;

#Supporter {
    align-items: center;
    max-width: 100vw;
    padding: 1rem;

    > h2 {
        margin-left: auto;
        margin-right: auto;
        max-width: supporter-page-width;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .fa-star {
        themed color supporter
    }

    #supporter-payment-block-container {
        max-width: 100vw;
        text-align: justify;
        margin-top: 3rem;

        .p {
            margin-top: 1rem;
        }

        .supporter-payment-buttons {
            margin: 2rem;
            display: flex;
            flex-wrap: wrap;
            max-width: 100vw;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            align-content: center;

            > div {
                margin: 1rem;
            }

            > button {
                margin-right: 1rem;
            }

            > .paypal {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                align-content: center;
            }
        }

    }

    #supporter-text-container {
        margin: auto;
        max-width: supporter-page-width;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #supporter-text {
        text-align: justify;

        .SiteSupporterText {
            margin: auto;
            max-width: 40rem;
        }
    }

    .support-thanks {
        margin-top: 1rem;
        text-align: center;
    }

    input[type="range"] {
        border: none;
    }

    .paypal-button, .stripe-button {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        height: 2.5rem;
        min-width: 15rem;
        max-width: 90vw;
        font-weight: bold;
    }


    .paypal-button {
        color: black;
        background-color: #FFC439;
        border-color: #FFAF00;

        &:disabled {
            cursor: not-allowed;
            themed background-color shade4
            themed border-color shade3
        }

        img {
            padding-left: 1rem;
        }
    }

    .stripe-button {
        color: white;
        border: 1px solid #895EB8;
        background-color: #67478A;
    }

    .LineText {
        margin: 1rem;
    }

    h3 {
        font-size: font-size-big;
        margin-bottom: 0;
    }
    h5 {
        margin-top: 0;
        text-align: center;
    }

    .donation-summary {
        font-size: font-size-big;
        font-weight: bold;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .powered-by-stripe {
        margin-top: 0.5rem;
    }

    .currency-select {
        display: inline-block;
        font-size: 1.0rem;
        width: 8rem;
        margin-right: 0.5rem;
        text-align: left;

        /*
        input {
            display: none !important;
            visibility: hidden !important;
        }
        */
    }

    .currency-option {
        .iso {
            display: inline-block;
            width: 2.6rem;
        }
    }

    .currency-option-container {
        //display: inline-flex;
        //align-items: center;
        padding-left: 0.5rem;
        > div {
            display: inline-block;
        }
    }


    .supporter-amount {
        width: 9rem;
        text-align: center;
        font-weight: bold;
        font-size: font-size-big;
    }

    .interval-select {
        width: 10rem;
        text-align: left;
        margin-left: 0.5rem;
        font-weight: bold;
        font-size: font-size-big;
    }

    #Supporter-custom-amount {
        width: 6rem;
        text-align: center;
        font-weight: bold;
        font-size: font-size-big;
    }


    .recurring-donation {
        text-align: center;
        padding: 1rem;
    }

    #supporter-payment-block {
    }
    #supporter-input-amount {
        text-align: center;
    }

    .other-payment-options {
        flex: 1;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    #supporter-current-methods {
        margin: auto;
        max-width: supporter-page-width;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;


        .recurring-donation {
            flex: 1;
            display: inline-block;
            max-width: 14rem;
            min-height: 14rem;
            border: 1px solid #888;
            themed border-color shade3
            border-radius: 0.5rem;
            margin: 1rem;
            display: flex;
            text-align: justify;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    #supporter-ai-perks {
        margin-top: 3rem;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;



        .supporter-perk-box {
            width: 15rem;
            max-width: 100vw;
            display: inline-flex;
            height: 10rem;
            margin: 0.5rem;
            border: 1px solid #888;
            border-radius: 0.5rem;
            themed border-color shade3
            padding: 0.5rem;
            flex-direction: column;
            themed background inactive-support-gradient
            themed color fg-increased-contrast
            text-decoration: none;
            opacity: 0.8;

            &:hover {
                opacity: 1.0;
            }

            &.active {
                themed background active-support-gradient
                themed border-color supporter
            }

            .title {
                flex: 0;
                font-weight: bold;
                font-size: font-size-mid;
                display: flex;
                margin-bottom: 0.5rem;
                flex-flow: row wrap;
                justify-content: space-between;
            }
            .text {
                flex: 1;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                .learn-more {
                    margin-top: 1rem;
                    text-align: right;
                }
            }
        }

    }

    #supporter-perks-star {
        text-align: right;
        themed color shade0
    }
}

.grayed-out-image {
    filter: grayscale(100%);
    cursor: not-allowed !important;
}

.supporter-learn-more-swal {
    .swal2-content {
        text-align: justify;
    }
}
