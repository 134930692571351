/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

game-chat-user-list-width=10rem;

.chat-container {
    display: flex;
    flex-direction: column;
    //flex-basis: 250px;
    min-height: 250px;
    border-radius: 0.3rem;
    //box-shadow: 0 3px 4px rgba(0,0,0,.400);
    //height: 100%;
    border: 1px solid transparent;
    themed border-color shade4
    flex-basis: 100vh;

    .log-player-container {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        width: 100%;
        max-height: calc(100% - 30px);
        position: relative;
    }

    .chat-log-container {
        background-color: red !important;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .ChatUserList {
        width: game-chat-user-list-width;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .log-player-container.show-player-list .chat-log-container {
        right: game-chat-user-list-width;
    }


    .chat-log {
        height: 100%;
        themed background-color shade5
        border-bottom: 1px solid transparent;
        overflow-y: scroll;
    }
    .chat-log.autoscrolling {
        themed border-bottom-color success
    }
    .chat-line.spectator {
        themed color spectator-text-color
    }
    .chat-line.third-person .Player, .chat-line.third-person .body {
        font-style: italic;
    }

    .chat-line {
        width: 100%;
        overflow-wrap: break-word;
    }

    .timestamp {
        @extend .monospace
        font-size: 10pt;
        themed color fg
    }
    .Player {
        font-size: font-size-small;
        margin-right: 0.5rem;
    }
    .body {

    }

    .variation, .position {
        font-weight: bold;
        //border: 1px solid #999;
        //background-color: #DDE9F2;
        themed color variation
        border-radius: 2px;
        margin-left: 0.2em;
        margin-right: 0.2em;
        padding-left: 0.2em;
        padding-right: 0.2em;
    }
    .variation:hover, .position:hover {
        //border: 1px solid #ccc;
        //background-color: #E7F3FC;
        themed color variation-hover
        text-decoration: underline;

    }

    .variation {
        cursor: pointer;
    }

    .position {
        cursor: crosshair;
    }



    .chat-input-player-list-toggle {
        border-top-right-radius: 0;
        box-shadow: none;
        white-space: nowrap;
    }
    .chat-input-chat-log-toggle {
        border-top-left-radius: 0;
        box-shadow: none;
        white-space: nowrap;
    }

    .chat-input-container {
        flex-shrink: 0;
        flex-grow: 0;
        height: 30px;
        flex-basis: 30px;
        display: flex;
        //max-width: 100%;
        //width: 100%;
        overflow: hidden;

        button {
            flex-shrink: 0;
        }

        input {
            min-width: 5rem;
            flex-grow: 1;
            flex-shrink: 1;
            height: 1.7rem;
            width: 5rem;
        }

        .qc-toggle {
            background-color: transparent;
            border-bottom: 1px solid #909090;
            height: 1.6rem;
            padding: 0rem 0.5rem 0rem 0.5rem;
        }
    }


    /*
    .chat-input-container {
        display: flex;
        align-items: stretch;

        .chat-input {
            flex-grow: 1;
        }
    }
    */

    .chat-line-container {
        width: 100%;
        //height: 100%;
    }
    .move-number {
        @extends .monospace
        font-size: font-size-smaller;
        cursor: pointer;
    }
    .date {
        @extends .monospace
        font-size: font-size-smaller;
    }

    .qc-option-list-container {
        position: relative;
        display: flex;
        background-color: transparent;

        ul {
            list-style-type: none;
            padding: 1em;
            margin: auto;
        }

        a {
            text-decoration: none;
            color: inherit;
            user-select: none;
        }

        a:hover {
            text-decoration: underline;
            color: inherit;
        }

        a:active {
            color: inherit;
        }

        .qc-edit {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

}

.malkovich, .malkovich.active, .malkovich:focus, .malkovich:hover {
    themed background-color malkovich-bg

}

