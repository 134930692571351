/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.Tutorial {
    // here
    display: flex;
    justify-content: center;

    .TutorialPage {
        text-align: center;

        .tutorial-text {
            font-size: font-size-big;
            min-height: 12rem;
            text-align: justify;
            padding: 1.5rem;
        }

        .buttons {
            text-align: right;
        }
    }

    .Done {
        width: 50rem;
        max-width: 100vw;
        text-align: justify;

        .col-sm-6 {
            text-align: center;
        }

        .btn, button {
            margin: 1rem;
            width: 15rem;
            max-width: 100vw;
            text-align: center
        }
    }

    .coordinate {
        themed color primary
    }
}
