/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.BlockPlayerModal {
    box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
    border: 1px solid transparent;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16), 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    themed background-color shade4
    themed border-color shade3

    .details {
        display: flex;
        flex-direction: column;

        input[type="checkbox"] {
            flex-shrink: 0;
        }
        label {
            flex-grow: 1;
            user-select: none;
        }

        .block-option {
            display: flex;
        }
    }
}
