/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.Developer {
    // here
    img {
        max-width: 100%;
    }

    a {
        @extends .monospace;
    }

    h1 {
        margin-top: 2rem;
    }

    .github-link {
        position: absolute;
        top: navbar-height;
        right: 0;
        border: 0;
    }

    iframe{
        border-width: 0;
        height: 2em;
        width: 12em;
        frameborder: 0;
        scrolling: 0;
    }
}
