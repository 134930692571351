/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.PuzzleList {
    // here

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;


    .puzzle-list-container {
        width: 100%;

        td.icon{
            width: 70px;
        }
    }

    td.name {
        font-weight: bold;
    }

    .MiniGoban {
        height: 64px;
        width: 64px;
    }
    .icon {
        padding: 0.2rem;
        text-align: center;
    }
    .rating-count {
        color: #919191;
    }
    .rating {
        .fa-star, .fa-star-half-o{
            color: #FFB74D;
        }
    }
}
