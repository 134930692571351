/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

#RulesMatrix {
    max-width: 100vw
    overflow: auto;

    #docs-go-rules-comparison-matrix {
        min-width: 60rem;
        /*
        table.rules {
            border: 1px solid transparent;
            themed border-color shade3
        }
        */

        table.rules th {
            themed background-color shade4
            padding: .5em;
        }


        table.rules th, table.rules td {
            width: 20em;
        }

        table.rules tbody td:nth-of-type(even) {
            themed background shade2
        }
        table.rules tbody td {
            border-top: 1px solid transparent
            border-right: 1px solid transparent
            border-left: 1px solid transparent
            themed border-color shade4

            padding-left: 0.5em;
            padding-right: 0.5em;
        }


        table.rules tr:nth-child(even) {
            td {
                themed background-color shade4
            }
        }

    }
}
