/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.PlayerDetails {
    box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
    border: 1px solid transparent;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16), 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    themed background-color shade4
    themed border-color shade3
    themed color fg
    max-width: 240px;
    padding: 0.2rem;

    .details {
        display: flex;
        justify-content: space-between;
        .icon {
            display: inline-block;
            flex-shrink: 0;
            position: relative;
            width: 64px;
            height: 64px;
            background-repeat: no-repeat;
            background-size: cover;

            .f16 {
                display: inline-block;
                position: absolute;
                bottom: 0;
                right: 0;
                height: 16px;
                width: 16px;
            }

        }
        .player-info {
            flex-shrink: 1;
            margin-left: 6px;
            max-width: 170px;
        }
        .player-link {
            cursor: pointer !important;
        }
        .player-link:hover {
            text-decoration: underline;
        }
        div {
            text-align: right;
        }
    }
    .actions {
        margin-top: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > button {
            min-width: 48%;
            text-align: left;
            .fa, .ogs-goban {
                padding-right: 0.5rem;
            }
        }
    }
}
