/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.TournamentIndicator {
    display: inline-flex;
    flex-direction: column;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    align-items: center;
    font-size: 0.9rem;
    margin-bottom: -3px;
    margin-top: 1px;

    .fa-trophy {
        font-size: 0.9rem;
        //color: #E7DB00;
        color: gold;

        //text-stroke: 1px black;
        text-shadow: 0px 0px 0 #888, -1px -1px 0 #888,  1px -1px 0 #888, -1px 1px 0 #888, 1px 1px 0 #888;
    }


    .time {
        font-size: font-size-smaller;
        padding-bottom: -5px;
    }
}
.TournamentIndicator:hover {
    text-decoration: none;

    .fa-trophy {
        color: #FFED00;
    }
}
