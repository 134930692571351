/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

#toast-meta-container {
    position: fixed;
    z-index: z.toast;
    top: navbar-height;
    right: 0;
    width: 20rem;
    max-width: 100%;

    /*
    &:hover .toast-container {
        opacity: 1.0;
    }
    */
}

&.zen #toast-meta-container {
    display: none;
}

.toast-container {
    themed background-color toast-bg
    themed color toast-fg
    padding: 0.5rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.26), 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
    cursor: pointer;

    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    //box-sizing: border-box;
    margin: 0;


    /*
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.16), 0px 4px 7px 0px rgba(0, 0, 0, 0.26);
    }
    */
}

.toast-position-container {
    display: inline-block;
    width: 100%;
    position: relative;
    //height: 10px;
    height: 0px;
    padding: 0;
    margin-bottom: 0.5rem;;
    user-select: none;

    transition: height 0.3s ease-out, opacity 0.3s linear;
    opacity: 0;
}
.toast-position-container.opaque {
    opacity: 1;
}
