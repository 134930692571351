/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.About {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-about {
        max-width: 30rem;
        text-align: justify;

        .title-version {
            position: relative;

            .links {
                position: absolute;
                right: 0;
                bottom: 0;
                font-size: font-size-small;

                a {
                    padding-left: 1rem;
                }
            }
        }

    }

    .server-time {
        margin-top: 2rem;
        text-align: center;
    }

    .about-links {
        display: flex;
        justify-content: space-between;
    }

    .version {
        padding-top: 2rem;
    }

    .github-link {
        position: absolute;
        top: navbar-height;
        right: 0;
        border: 0;
    }
}

