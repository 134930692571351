/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.GameLinkModal.Modal {
    width: 100%;
    max-width: 40rem;

}

.GameLink-kv {
    display: flex;
    align-items: flex-end;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    span {
        flex-grow: 0;
        width: 6rem;
        text-align: right;
        font-weight: bold;
        padding-right: 0.5rem;
        font-size: 1.2rem;
    }
    i {
        font-size: 1.0rem;

    }
    input {
        flex-grow: 1;
        width: 100%;
        border: none;
        font-size: 1.2rem;
    }
}
