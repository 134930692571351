/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.f16 .flag{display:inline-block;height:16px;width:16px;vertical-align:text-top;line-height:16px;background:url("https://cdn.online-go.com/assets/flags16.2019-01-12.png") no-repeat;}
.f16 ._African_Union{background-position:0 -16px;}
.f16 ._Arab_League{background-position:0 -32px;}
.f16 ._ASEAN{background-position:0 -48px;}
.f16 ._CARICOM{background-position:0 -64px;}
.f16 ._CIS{background-position:0 -80px;}
.f16 ._Commonwealth{background-position:0 -96px;}
.f16 ._England{background-position:0 -112px;}
.f16 ._European_Union, .f16 .eu{background-position:0 -128px;}
.f16 ._Islamic_Conference{background-position:0 -144px;}
.f16 ._Kosovo{background-position:0 -160px;}
.f16 ._NATO{background-position:0 -176px;}
.f16 ._Northern_Cyprus{background-position:0 -192px;}
.f16 ._Northern_Ireland{background-position:0 -208px;}
.f16 ._Olimpic_Movement{background-position:0 -224px;}
.f16 ._OPEC{background-position:0 -240px;}
.f16 ._Red_Cross{background-position:0 -256px;}
.f16 ._Scotland{background-position:0 -272px;}
.f16 ._Somaliland{background-position:0 -288px;}
.f16 ._Tibet{background-position:0 -304px;}
.f16 ._United_Nations{background-position:0 -320px;}
.f16 ._Wales{background-position:0 -336px;}
.f16 .ad{background-position:0 -352px;}
.f16 .ae{background-position:0 -368px;}
.f16 .af{background-position:0 -384px;}
.f16 .ag{background-position:0 -400px;}
.f16 .ai{background-position:0 -416px;}
.f16 .al{background-position:0 -432px;}
.f16 .am{background-position:0 -448px;}
.f16 .ao{background-position:0 -464px;}
.f16 .aq{background-position:0 -480px;}
.f16 .ar{background-position:0 -496px;}
.f16 .as{background-position:0 -512px;}
.f16 .at{background-position:0 -528px;}
.f16 .au{background-position:0 -544px;}
.f16 .aw{background-position:0 -560px;}
.f16 .ax{background-position:0 -576px;}
.f16 .az{background-position:0 -592px;}
.f16 .ba{background-position:0 -608px;}
.f16 .bb{background-position:0 -624px;}
.f16 .bd{background-position:0 -640px;}
.f16 .be{background-position:0 -656px;}
.f16 .bf{background-position:0 -672px;}
.f16 .bg{background-position:0 -688px;}
.f16 .bh{background-position:0 -704px;}
.f16 .bi{background-position:0 -720px;}
.f16 .bj{background-position:0 -736px;}
.f16 .bm{background-position:0 -752px;}
.f16 .bn{background-position:0 -768px;}
.f16 .bo{background-position:0 -784px;}
.f16 .br{background-position:0 -800px;}
.f16 .bs{background-position:0 -816px;}
.f16 .bt{background-position:0 -832px;}
.f16 .bw{background-position:0 -848px;}
.f16 .by{background-position:0 -864px;}
.f16 .bz{background-position:0 -880px;}
.f16 .ca{background-position:0 -896px;}
.f16 .cg{background-position:0 -912px;}
.f16 .cf{background-position:0 -928px;}
.f16 .cd{background-position:0 -944px;}
.f16 .ch{background-position:0 -960px;}
.f16 .ci{background-position:0 -976px;}
.f16 .ck{background-position:0 -992px;}
.f16 .cl{background-position:0 -1008px;}
.f16 .cm{background-position:0 -1024px;}
.f16 .cn{background-position:0 -1040px;}
.f16 .co{background-position:0 -1056px;}
.f16 .cr{background-position:0 -1072px;}
.f16 .cu{background-position:0 -1088px;}
.f16 .cv{background-position:0 -1104px;}
.f16 .cy{background-position:0 -1120px;}
.f16 .cz{background-position:0 -1136px;}
.f16 .de{background-position:0 -1152px;}
.f16 .dj{background-position:0 -1168px;}
.f16 .dk{background-position:0 -1184px;}
.f16 .dm{background-position:0 -1200px;}
.f16 .do{background-position:0 -1216px;}
.f16 .dz{background-position:0 -1232px;}
.f16 .ec{background-position:0 -1248px;}
.f16 .ee{background-position:0 -1264px;}
.f16 .eg{background-position:0 -1280px;}
.f16 .eh{background-position:0 -1296px;}
.f16 .er{background-position:0 -1312px;}
.f16 .es{background-position:0 -1328px;}
.f16 .et{background-position:0 -1344px;}
.f16 .fi{background-position:0 -1360px;}
.f16 .fj{background-position:0 -1376px;}
.f16 .fm{background-position:0 -1392px;}
.f16 .fo{background-position:0 -1408px;}
.f16 .fr{background-position:0 -1424px;}  .f16 .bl, .f16 .cp, .f16 .mf, .f16 .yt{background-position:0 -1424px;}
.f16 .ga{background-position:0 -1440px;}
.f16 .gb{background-position:0 -1456px;}  .f16 .sh{background-position:0 -1456px;}
.f16 .gd{background-position:0 -1472px;}
.f16 .ge{background-position:0 -1488px;}
.f16 .gg{background-position:0 -1504px;}
.f16 .gh{background-position:0 -1520px;}
.f16 .gi{background-position:0 -1536px;}
.f16 .gl{background-position:0 -1552px;}
.f16 .gm{background-position:0 -1568px;}
.f16 .gn{background-position:0 -1584px;}
.f16 .gp{background-position:0 -1600px;}
.f16 .gq{background-position:0 -1616px;}
.f16 .gr{background-position:0 -1632px;}
.f16 .gt{background-position:0 -1648px;}
.f16 .gu{background-position:0 -1664px;}
.f16 .gw{background-position:0 -1680px;}
.f16 .gy{background-position:0 -1696px;}
.f16 .hk{background-position:0 -1712px;}
.f16 .hn{background-position:0 -1728px;}
.f16 .hr{background-position:0 -1744px;}
.f16 .ht{background-position:0 -1760px;}
.f16 .hu{background-position:0 -1776px;}
.f16 .id{background-position:0 -1792px;}
.f16 .mc{background-position:0 -1792px;}
.f16 .ie{background-position:0 -1808px;}
.f16 .il{background-position:0 -1824px;}
.f16 .im{background-position:0 -1840px;}
.f16 .in{background-position:0 -1856px;}
.f16 .iq{background-position:0 -1872px;}
.f16 .ir{background-position:0 -1888px;}
.f16 .is{background-position:0 -1904px;}
.f16 .it{background-position:0 -1920px;}
.f16 .je{background-position:0 -1936px;}
.f16 .jm{background-position:0 -1952px;}
.f16 .jo{background-position:0 -1968px;}
.f16 .jp{background-position:0 -1984px;}
.f16 .ke{background-position:0 -2000px;}
.f16 .kg{background-position:0 -2016px;}
.f16 .kh{background-position:0 -2032px;}
.f16 .ki{background-position:0 -2048px;}
.f16 .km{background-position:0 -2064px;}
.f16 .kn{background-position:0 -2080px;}
.f16 .kp{background-position:0 -2096px;}
.f16 .kr{background-position:0 -2112px;}
.f16 .kw{background-position:0 -2128px;}
.f16 .ky{background-position:0 -2144px;}
.f16 .kz{background-position:0 -2160px;}
.f16 .la{background-position:0 -2176px;}
.f16 .lb{background-position:0 -2192px;}
.f16 .lc{background-position:0 -2208px;}
.f16 .li{background-position:0 -2224px;}
.f16 .lk{background-position:0 -2240px;}
.f16 .lr{background-position:0 -2256px;}
.f16 .ls{background-position:0 -2272px;}
.f16 .lt{background-position:0 -2288px;}
.f16 .lu{background-position:0 -2304px;}
.f16 .lv{background-position:0 -2320px;}
.f16 .ly{background-position:0 -2336px;}
.f16 .ma{background-position:0 -2352px;}
.f16 .md{background-position:0 -2368px;}
.f16 .me{background-position:0 -2384px;}
.f16 .mg{background-position:0 -2400px;}
.f16 .mh{background-position:0 -2416px;}
.f16 .mk{background-position:0 -2432px;}
.f16 .ml{background-position:0 -2448px;}
.f16 .mm{background-position:0 -2464px;}
.f16 .mn{background-position:0 -2480px;}
.f16 .mo{background-position:0 -2496px;}
.f16 .mq{background-position:0 -2512px;}
.f16 .mr{background-position:0 -2528px;}
.f16 .ms{background-position:0 -2544px;}
.f16 .mt{background-position:0 -2560px;}
.f16 .mu{background-position:0 -2576px;}
.f16 .mv{background-position:0 -2592px;}
.f16 .mw{background-position:0 -2608px;}
.f16 .mx{background-position:0 -2624px;}
.f16 .my{background-position:0 -2640px;}
.f16 .mz{background-position:0 -2656px;}
.f16 .na{background-position:0 -2672px;}
.f16 .nc{background-position:0 -2688px;}
.f16 .ne{background-position:0 -2704px;}
.f16 .ng{background-position:0 -2720px;}
.f16 .ni{background-position:0 -2736px;}
.f16 .nl{background-position:0 -2752px;}  .f16 .bq{background-position:0 -2752px;}
.f16 .no{background-position:0 -2768px;}  .f16 .bv, .f16 .nq, .f16 .sj{background-position:0 -2768px;}
.f16 .np{background-position:0 -2784px;}
.f16 .nr{background-position:0 -2800px;}
.f16 .nz{background-position:0 -2816px;}
.f16 .om{background-position:0 -2832px;}
.f16 .pa{background-position:0 -2848px;}
.f16 .pe{background-position:0 -2864px;}
.f16 .pf{background-position:0 -2880px;}
.f16 .pg{background-position:0 -2896px;}
.f16 .ph{background-position:0 -2912px;}
.f16 .pk{background-position:0 -2928px;}
.f16 .pl{background-position:0 -2944px;}
.f16 .pr{background-position:0 -2960px;}
.f16 .ps{background-position:0 -2976px;}
.f16 .pt{background-position:0 -2992px;}
.f16 .pw{background-position:0 -3008px;}
.f16 .py{background-position:0 -3024px;}
.f16 .qa{background-position:0 -3040px;}
.f16 .re{background-position:0 -3056px;}
.f16 .ro{background-position:0 -3072px;}
.f16 .rs{background-position:0 -3088px;}
.f16 .ru{background-position:0 -3104px;}
.f16 .rw{background-position:0 -3120px;}
.f16 .sa{background-position:0 -3136px;}
.f16 .sb{background-position:0 -3152px;}
.f16 .sc{background-position:0 -3168px;}
.f16 .sd{background-position:0 -3184px;}
.f16 .se{background-position:0 -3200px;}
.f16 .sg{background-position:0 -3216px;}
.f16 .si{background-position:0 -3232px;}
.f16 .sk{background-position:0 -3248px;}
.f16 .sl{background-position:0 -3264px;}
.f16 .sm{background-position:0 -3280px;}
.f16 .sn{background-position:0 -3296px;}
.f16 .so{background-position:0 -3312px;}
.f16 .sr{background-position:0 -3328px;}
.f16 .st{background-position:0 -3344px;}
.f16 .sv{background-position:0 -3360px;}
.f16 .sy{background-position:0 -3376px;}
.f16 .sz{background-position:0 -3392px;}
.f16 .tc{background-position:0 -3408px;}
.f16 .td{background-position:0 -3424px;}
.f16 .tg{background-position:0 -3440px;}
.f16 .th{background-position:0 -3456px;}
.f16 .tj{background-position:0 -3472px;}
.f16 .tl{background-position:0 -3488px;}
.f16 .tm{background-position:0 -3504px;}
.f16 .tn{background-position:0 -3520px;}
.f16 .to{background-position:0 -3536px;}
.f16 .tr{background-position:0 -3552px;}
.f16 .tt{background-position:0 -3568px;}
.f16 .tv{background-position:0 -3584px;}
.f16 .tw{background-position:0 -3600px;}
.f16 .tz{background-position:0 -3616px;}
.f16 .ua{background-position:0 -3632px;}
.f16 .ug{background-position:0 -3648px;}
.f16 .us{background-position:0 -3664px;}
.f16 .uy{background-position:0 -3680px;}
.f16 .uz{background-position:0 -3696px;}
.f16 .va{background-position:0 -3712px;}
.f16 .vc{background-position:0 -3728px;}
.f16 .ve{background-position:0 -3744px;}
.f16 .vg{background-position:0 -3760px;}
.f16 .vi{background-position:0 -3776px;}
.f16 .vn{background-position:0 -3792px;}
.f16 .vu{background-position:0 -3808px;}
.f16 .ws{background-position:0 -3824px;}
.f16 .ye{background-position:0 -3840px;}
.f16 .za{background-position:0 -3856px;}
.f16 .zm{background-position:0 -3872px;}
.f16 .zw{background-position:0 -3888px;}
.f16 .sx{background-position:0 -3904px;}
.f16 .cw{background-position:0 -3920px;}
.f16 .ss{background-position:0 -3936px;}
.f16 ._Klingon{background-position:0 -3952px;}
.f16 ._United_Federation_of_Planets{background-position:0 -3968px;}
.f16 ._Pirate{background-position:0 -3984px;}
.f16 ._Starfleet{background-position:0 -4000px;}
.f16 ._DOOP{background-position:0 -4016px;}
.f16 ._GoT_Arryn{background-position:0 -4032px;}
.f16 ._GoT_Baratheon{background-position:0 -4048px;}
.f16 ._GoT_Greyjoy{background-position:0 -4064px;}
.f16 ._GoT_Lannister{background-position:0 -4080px;}
.f16 ._GoT_Martell{background-position:0 -4096px;}
.f16 ._GoT_Stark{background-position:0 -4112px;}
.f16 ._GoT_Targaryen{background-position:0 -4128px;}
.f16 ._GoT_Tully{background-position:0 -4144px;}
.f16 ._GoT_Tyrell{background-position:0 -4160px;}
.f16 ._Esperanto{background-position:0 -4176px;}
.f16 .ct{background-position:0 -2048px;} /* same as .ki */
.f16    .ac{background-position:0 -4192px;}
.f16    .ic{background-position:0 -4208px;}
.f16    .cx{background-position:0 -4224px;}
.f16    .cc{background-position:0 -4240px;}
.f16    .an{background-position:0 -4256px;}
.f16    .cs{background-position:0 -4272px;}
.f16 .dg{background-position:0 -4304px;}
.f16 .gf{background-position:0 -4320px;}
.f16 .gs{background-position:0 -4336px;}
.f16 .hm{background-position:0 -544px;} /* same as .au */
.f16 .io{background-position:0 -4352px;}
.f16 .jt{background-position:0 -3664px;} /* same as .us */
.f16 .mi{background-position:0 -3664px;} /* same as .us */
.f16 .mp{background-position:0 -4368px;}
.f16 .nf{background-position:0 -4384px;}
.f16 .nu{background-position:0 -4400px;}
.f16 ._OGS{background-position:0 -4416px;}
.f16 .nu{background-position:0 -4432px;}
.f16 .cc{background-position:0 -4448px;}
.f16 .cx{background-position:0 -4464px;}
.f16 .gf{background-position:0 -4480px;}
.f16 .gs{background-position:0 -4496px;}
.f16 .io{background-position:0 -4512px;}
.f16 .mp{background-position:0 -4528px;}
.f16 .nf{background-position:0 -4544px;}
.f16 .pm{background-position:0 -4560px;}
.f16 .pn{background-position:0 -4576px;}
.f16 .tf{background-position:0 -4592px;}
.f16 .tk{background-position:0 -4608px;}
.f16 .wf{background-position:0 -4624px;}
.f16 ._GoT_Arryn{background-position:0 -4640px;}
.f16 ._GoT_Baratheon{background-position:0 -4656px;}
.f16 ._GoT_Greyjoy{background-position:0 -4672px;}
.f16 ._GoT_Lannister{background-position:0 -4688px;}
.f16 ._GoT_Martell{background-position:0 -4704px;}
.f16 ._GoT_Stark{background-position:0 -4720px;}
.f16 ._GoT_Targaryen{background-position:0 -4736px;}
.f16 ._GoT_Tully{background-position:0 -4752px;}
.f16 ._GoT_Tyrell{background-position:0 -4768px;}
.f16 ._Pirate{background-position:0 -4784px;}
.f16 ._Lord_Howe_Island{background-position:0 -4800px;}
