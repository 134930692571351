/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.GameAcceptModal.Modal {
    width: 100%;
    max-width: 30rem;

    dt {
        width: 50%;
    }
    dd {
        width: 50%;
    }
    p {
        padding: 1rem;
    }
    p.cheat-warning {
        text-align: center;
    }
    .cheat-alert {
        color: chocolate;  /* mmm chocolate! */
        margin-left: 0.5em;
        margin-right: 0.5em;
    }
}
