/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */


.AutomatchSettings {
    width: 44rem;
    max-width: 100vw;
    margin-top: 2rem;

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
    }

    .automatch-settings {
        margin-top: 2rem;
        td {
            padding-left: 1rem;
        }
        th {
            text-align: right;
            padding-right: 1rem;
            vertical-align: top;
            padding-top: 0.3rem;
        }
        td {
            width: 20rem;
            padding-bottom: 1.5rem;
        }
        i {
            font-size: font-size-small;
        }

        input[type="number"] {
            width: 2.5rem;
        }

        select {
            width: 12rem;
        }
    }
}
