
#MerchantLog {
    pre {
        max-width: 30rem;
        overflow-x: auto;

        &.meta {
            max-height: 45rem;
            overflow-y: scroll;
        }
        &.body {
            max-height: 45rem;
            overflow-y: scroll;
        }
    }

    th {
        min-width: 6rem;
    }
    td {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
